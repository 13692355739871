<template>
    <div class="dashboardChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {
           var option = {
           		series: [{
           			type: 'gauge',
           			axisLine: {
           				lineStyle: {
           					width: 4,
           					color: [
           						[0.5, '#f33a2c'],
           						[0.8, '#2679da'],
           						[1, '#2cdb4f']
           					]
           				}
           			},
           			pointer: {
           				width: 3,
           				itemStyle: {
           					color: 'auto'
           				}
           			},
           			axisTick: {
           				distance: -4,
           				length: 0,
           				lineStyle: {
           					color: '#fff',
           					width: 2
           				}
           			},
           			splitLine: {
           				distance: -4,
           				length: 4,
           				lineStyle: {
           					color: '#fff',
           					width: 1
           				}
           			},
           			axisLabel: {
           				color: 'auto',
           				distance: 6,
           				fontSize: 8
           			},
           			detail: {
           				valueAnimation: true,
           				formatter: '{value}%',
           				color: 'auto',
           				fontSize: 14,
           				offsetCenter: ['0', '30']
           			},
           			data: [{
           				value: 70
           			}]
           		}]
           	};

           	//var chartDom = document.getElementById('char12');
           	//var chart = echarts.init(chartDom);
           	//chart.setOption(option, true);


            let myChart = this.$echarts(this.$el);
            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.dashboardChart {
    width: 100%;
    height: 100%;
}
</style>